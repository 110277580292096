<template>
<div class="product yjdetail" >
 <Header url="product"/>
 <Head2 head2='2'  catechild="1" />

    <div class="pbox goodslist">
          <div class="w12 clearfix">
                    <div class="yjtitle">让洗护更简单</div>
                    <div class="yjzimu">Make washing and care easier</div>
                    <div class="yjdesc">更新迭代，优化升级，只为给你超凡体验</div>
                    <div class="linex2"> </div>

                    <div v-for="(item,index) in list" :key="index">
                        <div class="funitemtitle">{{item.title}}</div>

                        <div class="clearfix funbox" v-for="(item2,index2) in item.childcate" :key="index2">
                              <div class="funleftname">{{item2.title}}</div>
                              <div class="funitemlist">
                                    <div class="funitem fl" v-for="(item3,index3) in item2.detail" :key="index3">
                                          <div class="funcustitle">{{item3.title}}</div>
                                          <div class="funcusdesc">{{item3.desc}}</div>
                                    </div>
                              </div>
                        </div>
                   </div>
       
          </div>

    </div>

   
 
  <Footer />
  </div>
</template>

<script>
import '../../assets/css/index.css'
import './index.css'
import Header from '../../components/header'
import Head2 from '../../components/head2'

import Footer from '../../components/footer'
import {gongnengFun} from '../../request/api'
export default {
    name: 'Allfunction',
    components: {
        Header,
        Head2,
        Footer,
    },
    data() {
      return {
          list:[]
      }
    },
    mounted(){
       this.getData();
    },
    methods: {
          getData(){
                let that= this;
                gongnengFun().then(res=>{
                      that.list = res.data;
                })
          }
    }


}
</script>

